import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { getS3Url } from './get-s3-url';

export type S3VideoProps = {
  path?: string;
} & Omit<ComponentPropsWithoutRef<'video'>, 'src'>;
export const S3Video = forwardRef<HTMLVideoElement, S3VideoProps>(
  function S3Video({ path, ...rest }, ref) {
    const url = getS3Url(path);

    return <video ref={ref} src={url?.toString()} {...rest} />;
  },
);
