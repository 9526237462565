import { S3UserVideo } from '@/components/s3/user/s3-video';
import clsx from 'clsx';
import { S3UserImg } from '../../s3/user/s3-img';
import {
  expandedMediaMaxHeight,
  expandedMediaMaxWidth,
  postCardMediaExpandedImg,
  postCardMediaExpandedVideo,
  postCardMediaImg,
  postCardMediaVideo,
} from './post-card-media.css';

export const PostCardMediaItem = ({
  s3Key,
  userId,
  type,
  expanded,
  w = expanded ? expandedMediaMaxWidth : undefined,
  h = expanded ? expandedMediaMaxHeight : undefined,
}: {
  s3Key: string;
  userId: string;
  type: string;
  expanded?: boolean;
  w?: number;
  h?: number;
}) =>
  type.startsWith('video/') ? (
    <S3UserVideo
      preload={'metadata'}
      s3Key={s3Key}
      controls
      playsInline
      userId={userId}
      className={clsx(
        postCardMediaVideo,
        expanded && postCardMediaExpandedVideo,
      )}
      onClick={expanded ? (e) => e.stopPropagation() : undefined}
    />
  ) : (
    <S3UserImg
      s3Key={s3Key}
      loading={expanded ? 'eager' : 'lazy'}
      userId={userId}
      className={clsx(postCardMediaImg, expanded && postCardMediaExpandedImg)}
      w={w}
      h={h}
    />
  );
