import { PostCardMediaFragmentFragment } from '@/__generated__/graphql';
import { CarouselItem } from '@synoptic/ui-kit/carousel/carousel.js';
import { ModalRoot, ModalTrigger } from '@synoptic/ui-kit/modal/modal.js';
import { useIsTablet } from '@synoptic/ui-kit/responsive/hooks.js';
import clsx from 'clsx';
import { useState } from 'react';
import { PostCardMediaItem } from './post-card-media-item';
import { PostCardMediaModalContentCarousel } from './post-card-media-modal-content-carousel';
import {
  postCardMedia,
  postCardMediaCarouselItem,
  postCardMediaThumbnail,
} from './post-card-media.css';

const estimatedMaxWidthDesktop = 685;
const estimatedMaxWidthMobile = 295;

const getSizePerCount = (
  count: number,
  isTablet: boolean,
  idx: number,
  ratio: number,
) => {
  const maxDimension = isTablet
    ? { w: estimatedMaxWidthDesktop, h: estimatedMaxWidthDesktop / ratio }
    : { w: estimatedMaxWidthMobile, h: estimatedMaxWidthMobile / ratio };

  if (count === 2) {
    return { w: maxDimension.w / 2, h: maxDimension.h };
  }

  if (count === 3) {
    if (idx === 0) {
      return { w: maxDimension.w / 2, h: maxDimension.h };
    }
    return { w: maxDimension.w / 2, h: maxDimension.h / 2 };
  }

  if (count === 4) {
    return { w: maxDimension.w / 2, h: maxDimension.h / 2 };
  }

  return maxDimension;
};

const ratios = [16 / 9, 9 / 16, 4 / 3, 1 / 1] as const;

const minHeightToRatioResize = 96;

const getAspectRatio = (
  count: number,
  w?: number | null,
  h?: number | null,
) => {
  if (!w || !h) return ratios[0];

  const ratio = w / h;

  const keepOriginalRatio = count > 1 || h <= minHeightToRatioResize;

  if (keepOriginalRatio) {
    return ratio;
  }

  let closest = ratios[0];

  for (const r of ratios) {
    closest = Math.abs(r - ratio) < Math.abs(closest - ratio) ? r : closest;
  }

  return closest;
};

export const PostCardMedia = ({
  media,
  authorId,
  className,
}: {
  media?: Array<PostCardMediaFragmentFragment> | null;
  authorId: string;
  className?: string;
}) => {
  const isTablet = useIsTablet();

  const [zoomedIdx, setZoomedIdx] = useState<number | null>(null);

  if (!media || media.length === 0) return null;

  const withExpand = media.length > 1 || media[0].type?.startsWith('image/');

  const carouselItems = withExpand
    ? media.map(({ key, type }) => {
        return (
          <CarouselItem key={key} className={postCardMediaCarouselItem}>
            <PostCardMediaItem
              expanded
              s3Key={key}
              userId={authorId}
              type={type}
            />
          </CarouselItem>
        );
      })
    : [];

  return (
    <div
      className={clsx(postCardMedia, className)}
      data-count={media.length}
      data-testid={'post-card-media-container'}
    >
      {media.map(({ key, type, width, height }, idx) => {
        const aspectRatio = getAspectRatio(media.length, width, height);

        const thumbnail = (
          <div
            key={key}
            style={{
              aspectRatio,
            }}
            className={postCardMediaThumbnail}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <PostCardMediaItem
              s3Key={key}
              userId={authorId}
              type={type}
              {...getSizePerCount(media.length, isTablet, idx, aspectRatio)}
            />
          </div>
        );

        return withExpand ? (
          <ModalRoot
            key={key}
            open={zoomedIdx === idx}
            onOpenChange={(v) => setZoomedIdx(v ? idx : null)}
          >
            <ModalTrigger asChild>{thumbnail}</ModalTrigger>

            <PostCardMediaModalContentCarousel
              onClick={(e) => {
                setZoomedIdx(null);
                e.stopPropagation();
              }}
              authorId={authorId}
              startIndex={idx}
            >
              {carouselItems}
            </PostCardMediaModalContentCarousel>
          </ModalRoot>
        ) : (
          thumbnail
        );
      })}
    </div>
  );
};
