import {
  CarouselContent,
  CarouselNext,
} from '@synoptic/ui-kit/carousel/carousel.js';

import { UserBadge } from '@/components/user-badge/user-badge';
import {
  Carousel,
  CarouselPrevious,
} from '@synoptic/ui-kit/carousel/carousel.js';
import {
  ModalContent,
  ModalHeader,
  ModalTitle,
} from '@synoptic/ui-kit/modal/modal.js';
import { VisuallyHidden } from '@synoptic/ui-kit/visually-hidden/visually-hidden.js';
import { useMemo } from 'react';
import {
  postCardMediaCarousel,
  postCardMediaCarouselButton,
  postCardMediaCarouselContent,
  postCardMediaCarouselContentContainer,
  postCardMediaExpandedContainer,
  postCardMediaExpandedContent,
  postCardMediaExpandedHeader,
  postCardMediaExpandedOverlay,
} from './post-card-media.css';

export const PostCardMediaModalContentCarousel = ({
  onClick,
  authorId,
  startIndex,
  children,
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  authorId: string;
  startIndex: number;
  children: React.ReactNode;
}) => {
  const carouselOpts = useMemo(
    () => ({
      startIndex: startIndex,
    }),
    [startIndex],
  );

  return (
    <ModalContent
      onClick={onClick}
      className={postCardMediaExpandedContainer}
      contentClassName={postCardMediaExpandedContent}
      overlayClassName={postCardMediaExpandedOverlay}
    >
      <ModalHeader className={postCardMediaExpandedHeader} inversedClose>
        <UserBadge
          id={authorId}
          size="small"
          inversed
          underlineTitleOnHover={false}
        />

        <VisuallyHidden>
          <ModalTitle>Image attached to post</ModalTitle>
        </VisuallyHidden>
      </ModalHeader>

      <Carousel className={postCardMediaCarousel} opts={carouselOpts}>
        <CarouselPrevious
          className={postCardMediaCarouselButton}
          onClick={(e) => e.stopPropagation()}
        />
        <CarouselContent
          containerClassName={postCardMediaCarouselContentContainer}
          className={postCardMediaCarouselContent}
        >
          {children}
        </CarouselContent>
        <CarouselNext
          className={postCardMediaCarouselButton}
          onClick={(e) => e.stopPropagation()}
        />
      </Carousel>
    </ModalContent>
  );
};
