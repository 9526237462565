import { forwardRef } from 'react';
import { S3Video, S3VideoProps } from '../s3-video';
import { getUserPath } from './get-path';

export type S3UserVideoProps = {
  userId: string;
  s3Key?: string;
} & Omit<S3VideoProps, 'path'>;

export const S3UserVideo = forwardRef<HTMLVideoElement, S3UserVideoProps>(
  function S3UserImg({ userId, s3Key, ...rest }, ref) {
    return (
      <S3Video
        ref={ref}
        path={s3Key ? getUserPath(userId, s3Key) : undefined}
        {...rest}
      />
    );
  },
);
